<template>
	<!--  -->
	<!-- <el-dialog :title="$t('i18nn_c09a4ec2fe473b0a')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1000px"
		v-loading="loadingUpload"> -->
	<el-drawer :wrapperClosable="false" :title="'预约提货'" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
		size="500px">
		<el-form ref="form" :inline="true" :rules="formRules" :model="form" label-width="150px" v-loading="loadingUpload"
			:element-loading-text="$t('i18nn_1b4525c800280581')">

			<!-- {"id":"","remark":"备注","arriveWhDate":"到仓库日期","packageType":"类型","containerSize":"柜型","packingNo":"柜号","inWhPallte":"入库打托数"} -->
			<div>

				<el-form-item :label="'预约提货时间'" prop="">
					<el-date-picker v-model="form.planDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"  default-time="12:00:00"
						:placeholder="$t('FormMsg.Select_date_time')"></el-date-picker>
				</el-form-item>

				
			</div>
			<!-- </el-card> -->
		</el-form>

		<!-- <div slot="footer" class="dialog-footer"> -->
		<!-- <el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button> -->
		<!-- <el-button type="primary" @click="submitFormAction()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button> -->
		<!-- <el-button type="primary" v-else @click="submitExcelFormAction()">{{ $t('FormMsg.Save') }}</el-button> -->
		<!-- </div> -->
		<div class="drawer-footer">
			<!-- <el-button type="primary" plain @click="dialogPrtOrderExpress = false">{{ $t('i18nn_4e9fc68608c60999') }}
				</el-button> -->
			<el-button type="primary" icon="el-icon-tickets" @click="submitFormAction()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
		</div>
		<!-- </el-dialog> -->
	</el-drawer>
</template>
<script>
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
			// type: {//1提交附件并，提交打托完成，其他仅提交附件
			// 	// default: function() {
			// 	// 	return '';
			// 	// },
			// 	// type: String
			// }
		},
		components: {
			// HyElWhUpLoad
		},
		data() {
			return {
				dialogFile: false,
				loadingUpload: false,

				// relationId: '',
				// userId: '',
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				// saveErr: false,

				// beforeFileList:[],
				// listUrl: [],

				// tableData: [],

				// loading: false,
				// {"id":"","remark":this.$t('i18nn_15b3627faddccb1d'),"arriveWhDate":this.$t('i18nn_40859a22ab42e313'),"packageType":this.$t('i18nn_184333c81babf2f1'),"containerSize":this.$t('i18nn_977ed6286b514ff6'),"packingNo":this.$t('i18nn_3d3bc466f13154d4'),"inWhPallte":this.$t('i18nn_1bab2e4f2c65c40f')}
				form: {
					"id": null, //"",
					"planDate": null
				},

				formRules: {
					// sendOutType: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_select'),
					// 	trigger: 'change'
					// }]
					// palletCount: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
					// feePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
				},
				selectOption: {
					wh_container_size: [],
					wh_packing_type: []
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
			// this.getDicData();
		},
		methods: {
			initData() {

				this.form = {
					id: this.row.id,
					"planDate": this.row.planDate
				};

			},

			submitFormAction() {
				this.submitExcelFormHTTP();
			},

			//提交-
			submitExcelFormHTTP() {
				let formData = Object.assign({}, this.form);
				this.loadingUpload = true;
				this.$http
					.put(this.$urlConfig.WhTransferPlanComplate, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							this.dialogFile = false;
							this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
							this.$emit('success');
							// }
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						// this.saveErr = true;
						this.loadingUpload = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},

			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback();
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_container_size', 'wh_packing_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_container_size = data.data['wh_container_size'];
			// 				this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询数据字典失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>